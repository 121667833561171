<template>
  <div class="w-100 d-flex flex-column">
    <header-bar></header-bar>
    <div class="h-100 flex-column d-flex justify-content-center">
      <h1>{{pageTitle}}</h1>
      <div class="text-block">
        <div class="row">
          <div class="col">
            <img :src="require('@/assets/images/toilets.png')" class="info-icon toilets">
            <div class="icon-text">{{toiletsText}}</div>
          </div>
          <div class="col">
            <img :src="require('@/assets/images/Gastro.png')" class="info-icon bars">
            <div class="icon-text">{{barsText}}</div>
          </div>
          <div class="col">
            <img :src="require('@/assets/images/Kasy.png')" class="info-icon tickets">
            <div class="icon-text">{{ticketsText}}</div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <img :src="require('@/assets/images/Wyjscie.png')" class="info-icon exit">
            <div class="icon-text">{{exitText}}</div>
          </div>
          <div class="col">
            <img :src="require('@/assets/images/Tablica.png')" class="info-icon tables">
            <div class="icon-text">{{tablesText}}</div>
          </div>
          <div class="col">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/header/Bar";
import store from '../../store/store.js'

export default {
  components: {HeaderBar},
  name: "InfoPage",
  data() {
    return {
      store: store,
      pageTitle: '',
      toiletsText: '',
      barsText: '',
      photoText: '',
      ticketsText: '',
      exitText: '',
      showsText: '',
      tablesText: '',
    }
  },
  mounted() {
    store.phoneButton = true;
    store.mapButton = true;
    store.infoButton = false;
    store.showBackButton = true;
    store.logoSmall = true;
    this.getVariables();
  },
  methods: {
    getVariables() {
      let storage = JSON.parse(localStorage.getItem('ogrod'));
      this.pageTitle = storage.pages.info.h1;
      this.toiletsText = storage.pages.info.toiletsText;
      this.barsText = storage.pages.info.barsText;
      this.photoText = storage.pages.info.photoText;
      this.ticketsText = storage.pages.info.ticketsText;
      this.exitText = storage.pages.info.exitText;
      this.showsText = storage.pages.info.showsText;
      this.tablesText = storage.pages.info.tablesText;
    },
  },
}
</script>

<style scoped>
.info-icon{
  width: 35px;
  height: auto;
  padding: 20px 0px 10px 0px;
}
.icon-text{
  font-size: 10pt;
  line-height: 14pt;
}
</style>
